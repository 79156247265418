import { JsonPipe, NgClass, NgForOf, NgIf, SlicePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NbButtonModule, NbInputModule, NbSelectModule } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { sortBy } from 'lodash';

import { DictionaryModel } from '../../../../models/dropdown.model';
import { DynamicFormControlParams } from '../../../../models/dynamic-form.model';

@Component({
  selector: 'bia-bare-dynamic-select',
  standalone: true,
  imports: [
    NbSelectModule,
    ReactiveFormsModule,
    NgSelectModule,
    NbInputModule,
    NgIf,
    NgForOf,
    SlicePipe,
    NgClass,
    JsonPipe,
    NbButtonModule,
  ],
  template: `
    <ng-select
      #select
      [appendTo]="appendTo"
      [id]="controlParams?.name"
      [items]="controlParams?.options"
      bindLabel="name"
      bindValue="id"
      placeholder="Select"
      [formControl]="controlParams?.control"
      [closeOnSelect]="!controlParams?.multiSelect"
      [multiple]="controlParams?.multiSelect"
      [clearable]="!controlParams?.multiSelect && !controlParams?.required && controlParams?.control.value"
      [searchable]="false"
      [searchFn]="customSearchFn"
      [title]="title"
      [groupBy]="controlParams?.multiSelect ? groupByFn : null"
      [groupValue]="groupValueFn"
      (close)="onClose(select.selectedValues)"
      (remove)="onClose(select.selectedValues)"
    >
      <ng-template ng-header-tmp>
        <input
          nbInput
          placeholder="Search"
          fieldSize="small"
          [fullWidth]="true"
          type="text"
          (input)="select.filter($event.target.value)"
        />
        @if (controlParams?.multiSelect) {
          <button (click)="selectAll()" nbButton size="small" status="info" class="mr-2 mt-2">Select all</button>
          <button (click)="unselectAll()" nbButton size="small" status="info" class="mr-2 mt-2">Unselect all</button>
        }
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items | slice: 0 : 1">
          <span class="ng-value-label"> {{ item.name }}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{ items.length - 1 }} more...</span>
        </div>
      </ng-template>
      <ng-template ng-optgroup-tmp let-item="item"> </ng-template>
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        @if (controlParams?.multiSelect) {
          <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected" />
        }
        {{ item.name }}
      </ng-template>
    </ng-select>
  `,
  styles: [
    `
      ng-select {
        min-width: 16rem;
        max-width: 30rem;
        display: block;
      }
    `,
  ],
})
export class BiaBareDynamicSelectComponent implements OnInit {
  @Input() appendTo: string;
  @Input() controlParams: DynamicFormControlParams;

  title: string = '';

  ngOnInit(): void {
    if (this.controlParams?.multiSelect) {
      this.controlParams.options = this.sortOptions(this.controlParams?.options ?? []);
      this.title = this.controlParams.control.value?.map((item) => item)?.toString() ?? '';
    } else {
      this.title = this.controlParams.control.value;
    }
  }

  onChange(s) {
    console.log('====>', this.controlParams.name, s);
  }

  onClose = (selectedItems: DictionaryModel[]) => {
    if (this.controlParams?.multiSelect) {
      this.controlParams.options = this.sortOptions(this.controlParams?.options ?? []);
    }
    this.title = selectedItems?.map((item) => item.name)?.toString();
  };

  groupByFn = (item: DictionaryModel) => {
    return this.controlParams.control.value?.includes(item.id);
  };

  groupValueFn = (_: string, children: DictionaryModel[]) => {
    return { name: '', total: children.length };
  };

  selectAll() {
    this.controlParams.control.setValue(this.controlParams.options.map(({ id }) => id));
  }

  unselectAll() {
    this.controlParams.control.setValue([]);
  }

  customSearchFn(term: string, item: DictionaryModel) {
    return item.name?.toLowerCase().includes(term.toLowerCase()) || item.id?.toString().indexOf(term) === 0;
  }

  private sortOptions(options: any[]) {
    return [
      ...sortBy(
        options.filter(({ id }) => this.controlParams.control?.value?.includes(id)),
        'name',
      ),
      ...sortBy(
        options.filter(({ id }) => !this.controlParams.control?.value?.includes(id)),
        'name',
      ),
    ];
  }
}
