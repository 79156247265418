import { JsonPipe, NgClass, NgForOf, NgIf, SlicePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NbButtonModule, NbInputModule, NbSelectModule } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';

import { DynamicFormControlParams } from '../../../../models/dynamic-form.model';
import { BiaBareDynamicSelectComponent } from '../bare/bare-dynamic-select.component';

@Component({
  selector: 'ngx-dynamic-select',
  standalone: true,
  imports: [
    NbSelectModule,
    ReactiveFormsModule,
    NgSelectModule,
    NbInputModule,
    NgIf,
    NgForOf,
    SlicePipe,
    NgClass,
    JsonPipe,
    NbButtonModule,
    BiaBareDynamicSelectComponent,
  ],
  template: `
    <div class="labeled-control" [ngClass]="{ error: controlParams?.control.invalid && controlParams?.control.dirty }">
      <div class="label">
        {{ controlParams?.caption }} <span *ngIf="controlParams?.required" class="text-danger">*</span>
      </div>
      <bia-bare-dynamic-select [controlParams]="controlParams" [appendTo]="appendTo"></bia-bare-dynamic-select>
      <div *ngIf="controlParams?.control.hasError('required') && controlParams?.control.dirty" class="error">
        Please select the value
      </div>
    </div>
  `,
  styles: [
    `
      ng-select {
        min-width: 16rem;
        max-width: 30rem;
        display: block;
      }
    `,
  ],
})
export class BiaLabeledDynamicSelectComponent {
  @Input() appendTo: string;
  @Input() controlParams: DynamicFormControlParams;
}
